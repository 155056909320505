@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';


%arrow_shared {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-right: auto;
  right: 3%;
  @include transition;
}

.wrapper {
  position: relative;
}

.left__icon {
  z-index: 99;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  left: 12px;
  width: 24px;
}


.label {
  margin-bottom: 5px;
}

.label__fieldset {
  position: absolute;
  z-index: 2;
  background-color: white;
  font-family: $primary-SemiBold;
  width: fit-content;
  padding: 0 12px;
  margin-left: 4px;
  border-radius: 6px;
  margin-top: 12px;
  @include transition;

  &__active {
    margin-top: -12px;
    padding: 0 4px;
    margin-left: 12px;
    background-color: white !important;
  }
}


.select {
  position: relative;
  padding: 0;
  background-repeat: no-repeat;
  background-position: right 20px top 22px;
  font-family: $primary-medium;
  color: $txt-primary;
  font-size: $level2;
  cursor: pointer;
  @include border-radius(8px);
  @include transition;

  &__arrow {

    &_closed {
      @extend %arrow_shared;
    }

    &_open {
      @extend %arrow_shared;
      transform: rotateZ(180deg);
    }

  }

  &__placeholder {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 12px;
    padding-right: 20px;
    line-height: 1.3;

    &__position {
      margin-left: 25px;
    }

    &__new_style {
      padding: 5px 12px 4px 20px;
      font-family: $primary-reg;

      @include media(s-device) {
        padding: 5px 30px 4px 20px;
      }

      &__active {
        border: 1px solid $txt-primary !important;
      }
    }
  }

  &__control {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 48px;
    padding: 10px 0;
    background-color: white;
    border: 1px solid $ui-quaternary;
    @include border-radius(6px);
    @include transition;

    @include media(m-device) {
      width: 100%;
      height: fit-content;
    }

    &:hover {
      border: 1px solid $ui-tertiary;
    }

    &__is_open {
      color: $txt-primary;
      border-color: rgba(226, 43, 118, 0.48);
      box-shadow: 0 0 0 4px rgba(226, 43, 118, 0.30);

      &:hover {
        border: 1px solid rgba(226, 43, 118, 0.48);;
      }

    }
  }

  &__menu {
    z-index: 99;
    position: absolute;
    width: 100%;
    margin-top: 10px;
    padding: 8px;
    background-color: $background-1;
    box-shadow: 4px 6px 40px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid $ui-quaternary;
    overflow-y: auto;
    cursor: pointer;
    line-height: 1.4;
    @include border-radius(6px);
    @include transition;


    & > div {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 48px;
      padding: 10px;
      color: $txt-primary;
      @include transition;
      @include border-radius(6px);

      &:hover {
        background-color: $ui-quaternary;
        color: $txt-primary;
      }
    }

    @include media(m-device) {
      width: 100%;
    }

    &::-webkit-scrollbar-thumb {
      @include border-radius(6px);
      background-color: $ui-tertiary;
    }

    &::-webkit-scrollbar {
      width: 8px;
      margin-right: 5px;
    }
  }
}



