$primary-reg: 'Poppins-Regular', sans-serif;
$primary-reg-i: 'Poppins-Italic', sans-serif;
$primary-bold: 'Poppins-Bold', sans-serif;
$primary-bold-i: 'Poppins-BoldItalic', sans-serif;
$primary-medium: 'Poppins-Medium', sans-serif;
$primary-medium-i: 'Poppins-MediumItalic', sans-serif;
$primary-SemiBold: 'Poppins-SemiBold', sans-serif;
$primary-SemiBold-i: 'Poppins-SemiBoldItalic', sans-serif;
$primary-platforms: 'OpenSans', sans-serif;
$odvp-primary-reg: 'Montserrat-Regular', sans-serif;
$odvp-primary-bold: 'Montserrat-Bold', sans-serif;
$odvp-secondary-reg: 'Poplar-Standart', sans-serif;
$odvp-primary-2022: 'christmas-bell-personal-use', sans-serif;
$odvp-allenoire-regular: 'AllenoireFreePersonal';

@font-face {
  font-family: 'Montserrat-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('/front-static/fonts/Montserrat-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Bold';
  font-style: normal;
  font-weight: normal;
  src: url('/front-static/fonts/Montserrat-Bold.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'christmas-bell-personal-use';
  font-style: normal;
  font-weight: normal;
  src: url('/front-static/fonts/christmas-bell-personal-use.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Poplar-Standart';
  font-style: normal;
  font-weight: normal;
  src: url('/front-static/fonts/Poplar-Standart.otf');
  font-display: swap;
}


@font-face {
  font-family: 'Poppins-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('/front-static/fonts/Poppins-Regular.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Italic';
  font-style: italic;
  font-weight: normal;
  src: url('/front-static/fonts/Poppins-Italic.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Bold';
  font-style: normal;
  font-weight: bold;
  src: url('/front-static/fonts/Poppins-Bold.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-BoldItalic';
  font-style: italic;
  font-weight: bold;
  src: url('/front-static/fonts/Poppins-BoldItalic.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Medium';
  font-style: normal;
  font-weight: 500;
  src: url('/front-static/fonts/Poppins-Medium.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-MediumItalic';
  font-style: italic;
  font-weight: 500;
  src: url('/front-static/fonts/Poppins-MediumItalic.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  font-style: normal;
  font-weight: 600;
  src: url('/front-static/fonts/Poppins-SemiBold.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-SemiBoldItalic';
  font-style: italic;
  font-weight: 600;
  src: url('/front-static/fonts/Poppins-SemiBoldItalic.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'AllenoireFreePersonal';
  font-style: normal;
  font-weight: 600;
  src: url('/front-static/fonts/Allenoire-Font.otf');
  font-display: swap;
}
