@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';
@import '../../../assets/styles/typography';
@import '../../../assets/styles/fonts';

.wrapper {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  width: 100%;

  & > div > div {
    z-index: 10 !important;
  }
}

.migrated_wrapper {
  margin-top: -20px;
}

.label {
  padding-bottom: 4px;

  &__fieldset {
    margin-bottom: -40px;
    padding: 4px 16px;
    z-index: 12;
    font-family: $primary-SemiBold;
    cursor: pointer;
    @include transition();

    &__active {
      margin-bottom: -12px;
      background-color: white;
      width: fit-content;
      padding: 0 4px;
      margin-left: 12px;
      border-radius: 6px;
    }
  }
}


.input {
  composes: input from '../InputField/InputField.module.scss';
  background-image: url("/front-static/icons/display/calendar.svg");
  background-position: right 12px center;
  background-repeat: no-repeat;
  cursor: pointer;
  font-family: $primary-reg;

  &__new_style_active {
    border: 1px solid $txt-primary;
  }

  &__disabled {
    cursor: unset;
  }
}

.calendar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  border-radius: 8px;
  box-shadow: 12px 12px 48px 0 rgba(163, 175, 194, 0.18);
  border: solid 1px $ui-quaternary;

  & > span {
    display: none;
  }

  .custom_btn {
    height: 26px;
    width: 26px;
    padding: 6px;
  }

  select {
    padding: 2px 6px;
    border-radius: 4px;
    border: solid 1px $ui-quaternary;
    color: $txt-primary;
    font-family: $primary-reg;
    font-size: 14px;

    &:first-of-type {
      margin-right: 4px;
    }
  }

  .day {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    line-height: 32px;
    color: $txt-primary;
    font-family: $primary-reg;
    font-size: $level1;
    padding: 0;

    &:hover {
      background-color: $brand-primary-muted;
      color: $brand-primary;
    }
  }


  :global {
    .react-datepicker {

      &__month-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      &__navigation {
        width: 24px;
        height: 24px;
        border: none;

        &--next {
          background-image: url("/front-static/icons/navigation/chevron-right.svg");
          background-position: center;
          background-repeat: no-repeat;
          top: 10px;
        }

        &--previous {
          background-image: url("/front-static/icons/navigation/chevron-right.svg");
          transform: rotate(180deg);
          background-position: center;
          background-repeat: no-repeat;
          top: 10px;

        }
      }

      &__header {
        width: 100%;
        border: none;
        margin: 0 auto;
        background-color: $white;
        padding: 0;
      }

      &__current-month {
        font-family: $primary-medium;
        font-size: $level2;
        color: $txt-primary;
        padding: 16px;
      }

      &__month {
        width: 100%;
      }

      &__week {
        display: flex;
        justify-content: space-evenly;
      }

      &__day-names {
        display: flex;
        justify-content: space-evenly;
      }

      &__day-name {
        color: $txt-secondary;
      }

      &__day--selected {
        font-family: $primary-medium;
        color: $brand-primary;
        background-color: $brand-primary-muted;

        &:hover {
          background-color: $brand-primary-muted;
        }
      }

      &__day--disabled {
        color: $ui-secondary;

        &:hover {
          background-color: unset;
          color: $ui-secondary;
        }
      }

      &__day--keyboard-selected {
        font-family: $primary-medium;
        color: $brand-primary;
        background-color: $brand-primary-muted;
      }
    }
  }
}
