@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';


.navbar {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  transition: transform 350ms ease-in-out;

  &:before {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: '';
    bottom: 0;
    width: 100vw;
    transition: transform 350ms ease-in-out;
    transform: translateY(-100%);
    background-color: $white;
  }

  &__white {
    background-color: $white;
    border-bottom: 1px solid $ui-quaternary;
  }

  &__transparent {
    background-color: $ui-transparent;
    border-bottom: 1px solid $ui-transparent;
  }

  &__active {
    &:before {
      background-color: $white;
      transition: transform 350ms ease-in-out;
      transform: translateY(0px);
    }
  }

  &__title {
    transition: all 400ms ease-in-out;
    opacity: 0;
    transform: translateY(20px);

    &__visible {
      opacity: 1;
      transform: translateY(0);
      transition: all 400ms ease-in-out;
    }
  }


  &__left {
    display: flex;
    align-items: center;

    &__logo {
      margin-right: 10px;
      vertical-align: middle;
      cursor: pointer;
    }

    &__mpp_logo {
      width: 130px;
      height: 70px;
      margin-left: 10px;
      vertical-align: middle;
      cursor: pointer;
    }
  }


  &__right {
    display: flex;
    align-items: center;

    &__download {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 38px;
      margin: 0 16px;
      border-radius: 8px;
      background-color: $white;
      cursor: pointer;
      border: none;
    }

    &__participate {
      width: unset;
      margin-right: 8px;
    }


    &__btn {
      height: 38px;
      padding: 16px;
      margin-left: 8px;
      font-size: $level2;
    }
  }

  &__need_help {
    margin-right: 25px;

    &__selected {
      margin-right: 25px;
      font-weight: bold;
    }
  }

}








