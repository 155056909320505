@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';
@import '../../../../assets/styles/fonts';

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $ui-quaternary;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
