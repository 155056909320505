@import '../../../assets/styles/colors';
@import '../../../assets/styles/osflm_colors';
@import '../../../assets/styles/fonts';
@import '../../../assets/styles/mixins';


/* Customize the label (the container) */
.container {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  font-family: $primary-reg;
  font-size: 1rem;
  min-height: 25px;
  width: fit-content;
  cursor: pointer;


  &__disabled {
    cursor: default;
  }

  /* Hide the browser's default checkbox */
  > input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

  }

  /* Create a custom checkbox */
  .checkmark {
    height: 20px;
    width: 20px;
    min-width: 20px;
    min-height: 20px;
    margin-right: 8px;
    border: 1px solid $txt-secondary;
    cursor: pointer;
    @include border-radius(4px);
    @include transition();

    &:hover {
      border: 1px solid $ui-tertiary;
    }

    &__error {
      border: 1px solid $ui-error;

      &:hover {
        border: 1px solid darken($color: $ui-error, $amount: 10%);
      }
    }

    /* Style the checkmark/indicator */
    &:after {
      content: "";
      position: absolute;
      width: 18px;
      height: 18px;
      background-image: url("/front-static/icons/placeholder/checkmark.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }


    &__checked {
      background-color: $brand-primary;
      border: 1px solid $brand-primary;
      @include border-radius(4px);

      &__customColor {
        background-color: $osflm-primary;
      }

      &:hover {
        border: 1px solid lighten($color: $brand-primary, $amount: 10%);
        background-color: lighten($color: $brand-primary, $amount: 10%);
      }

      &__success {
        background-color: $ui-success;
        border: 1px solid $ui-success;
        @include border-radius(4px);

        &:hover {
          border: 1px solid lighten($color: $ui-success, $amount: 10%);
          background-color: lighten($color: $ui-success, $amount: 10%);
        }
      }

      &__error {
        background-color: $ui-error;
        border: 1px solid $ui-error;
        @include border-radius(4px);

        &:hover {
          border: 1px solid lighten($color: $ui-error, $amount: 10%);
          background-color: lighten($color: $ui-error, $amount: 10%);
        }
      }
    }


    &__disabled {
      border: none;
      background-color: $ui-quaternary;
      cursor: default;

      &:hover {
        border: none;
      }
    }

  }

}


/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}




