@import '../../../assets/styles/colors';
@import '../../../assets/styles/fonts';
@import '../../../assets/styles/mixins';
@import '../../../assets/styles/typography';

.trust {
  height: 92px;
  padding-top: 32px;
  cursor: pointer;

  @include media(m-device) {
    border: none;
  }
}
