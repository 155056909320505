@import '../../../../../assets/styles/mixins';

.btn {
  width: 136px;
  height: 38px;
  font-size: 14px;
}

.btn_container {
  display: flex;

  @include media(s-device) {
    flex-direction: column;
  }
}
