$white: #ffffff;
$black: #000000;
$orange: #ff6900;
$green: #56BC81;

$apple-blue: #0A84FF;

$brand-primary: #E22B76;
$brand-primary-shade: rgba(226, 43, 118, 0.48);
$brand-primary-box-shadow: rgba(226, 43, 118, 0.30);
$brand-secondary: #00A5E2;
$brand-accent: #FAC700;
$brand-accent-secondary: #FEF4CC;
$brand-accent-lighten: #FEF3CC;
$brand-primary-muted: #FCE9F1;
$brand-secondary-muted: #E0F1FF;

$ui-primary: #111e3d;
$ui-secondary: #A3AFC2;
$ui-secondary-shade: #E6EAF0;
$ui-secondary-shade-2: #F1F6FC;
$ui-secondary-shade-primary: #DBE0E8;
$ui-secondary-shade-secondary: #F3F5F8;
$ui-secondary-box-shadow: #F7F8FA;
$ui-background-grey: #F6F6F6;
$ui-tertiary: #E1E5EB;
$ui-tertiary-box-shadow: #F6F7F9;
$ui-quaternary: #EBEFF5;
$ui-quaternary-muted: #EFF4FA;
$ui-quaternary-muted-secondary: #fbfcfd;
$ui-success: #2BE283;
$ui-success-tint-1: #D4F9E6;
$ui-success-tint-2: #D0F5E2;
$ui-success-tint-3: #d5fae7;
$ui-error: #fb2838;
$ui-error-tint-1: #FED4D7;
$ui-error-tint-2: #FECBCF;
$ui-error-tint-3: #ffd4d8;
$ui-error-box-shadow: #FEEFF0;
$ui-warning: #EB853D;
$ui-warning-secondary: #fde8d9;
$ui-warning-lighten: #FDE7D8;
$ui-transparent: #FFFFFF00;
$ui-dark-grey: #424242;

$txt-primary: #232e69;
$txt-secondary: #a3afc2;
$txt-disabled: #9c9c9c;
$txt-alert: #f58b40;
$txt-alert-light: #fde8d9;


$background-1: #FFFFFF;
$background-2: #f0f4fa;
$background-light-grey: #f2f7fc;
$background-light-grey-2: #f2f7fd;
$background-dark: #0b0c0f;
$background-light-dark: #191a1f;


$light-grey: #CCC;

$txt-primary: #232e69;

$box-shadow: rgba(163, 175, 194, 0.18);


$odvp-strawberry: #EB3B41;
$odvp-background: #171D47;
$odvp-greyish-brown: #3d3937;
$odvp-very-light-pink: #ffefe6;
$odvp-btn: #e94694;


// NEW COLORS FOR THE NEW DESIGN
$black-gray: #222222;
$light-black-gray: #79818D;
$dark-gray: #A3AFC233;
$trust-pilot-green: #00B670;
$confirm: #16894D;






