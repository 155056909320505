@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';

%shared-trigger {
  width: 100%;
  position: relative;
  font-family: $primary-medium;
  font-size: $level3;
  line-height: 1.5;
  color: $txt-primary;
  cursor: pointer;

  &:before {
    position: absolute;
    width: 100%;
    content: '';
    border-top: 1px solid $background-2;
    bottom: -16px;
  }

}


%shared-icon {
  content: "";
  position: absolute;
  left: 95%;
  width: 20px;
  height: 20px;
  background-image: url("/front-static/icons/navigation/chevron-right.svg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: cover;
  @include transition;
}


.collapsible {
  width: 100%;
  margin: 16px auto 16px auto;

  &:before {
    position: absolute;
    content: '';
    border-top: 1px solid $background-2;
  }
}


.trigger {


  @extend %shared-trigger;


  &:after {
    @extend %shared-icon;
  }

  &__opened {
    @extend %shared-trigger;

    &:after {
      @extend %shared-icon;
      transform: rotate(90deg);
    }
  }
}

.content {


  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: $primary-reg;
    font-size: $level3;
    color: $ui-secondary;
    padding: 8px 0 8px 0;
  }

  &__outer {
    margin-top: 16px;
  }

  &__icon {
    margin-left: 5px;
    width: 16px;
  }


}

