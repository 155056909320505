$level0: 11px;
$level1: 12px;
$level2: 14px;
$level3: 16px;
$level4: 18px;
$level5: 20px;
$level6: 22px;
$level7: 24px;
$level8: 32px;
$level9: 38px;
$level10: 40px;
$level11: 48px;
$level12: 56px;
$level13: 80px;
