@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/typography';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';

.overlay {
  z-index: unset;
  @include media(m-device) {
    top: 56px;
  }
}

.root {
  z-index: 1000;
}

.modal_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  padding: 24px;
  position: relative;
  border-radius: 12px;

  @include media(m-device) {
    min-width: 100%;
    padding: 20px;
  }

  h3 {
    text-transform: uppercase;
    text-align: center;
    margin: 32px 0;
  }

  &__transition_enter {
    opacity: 1;
    transform: translateY(0);
  }

  &__transition_enter {
    opacity: 0.01;

    .modal {
      transform: translateY(-100px);
    }
  }

  &__transition_enter_active {
    opacity: 1;
    transition: opacity 500ms cubic-bezier(0.23, 1, 0.32, 1), transform 500ms cubic-bezier(0.23, 1, 0.32, 1);

    .modal {
      transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
      transform: translateY(0);
    }
  }

  &__transition_exit {
    opacity: 1;
    transform: translateY(0);
  }

  &__transition_exit_active {
    opacity: 0.01;
    transition: opacity 500ms cubic-bezier(0.23, 1, 0.32, 1);

    .modal {
      transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
      transform: translateY(-100px);
    }
  }
}


.modal__close_button {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 10;

  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &:hover, &:focus {
    outline: none;
  }
}
