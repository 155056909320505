$osflm-primary: #fcca86;
$osflm-secondary: #e86a50;
$ui-primary: #6392ef;
$ui-secondary: #fbdd94;
$ui-secondary-shade: #fbb889;
$ui-error: #fb2838;
$txt-primary: #646769;
$txt-secondary: #060606;


