@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';
@import '../../../../assets/styles/fonts';

.container {
  z-index: 1000; // to be above navbar ;
  position: absolute;
  top: 90px;
  right: 50px;
  display: flex;
  flex-direction: column;
  min-width: 220px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 12px 12px 48px 0 $box-shadow;
  border: solid 1px $ui-quaternary;
  background-color: $white;
  visibility: hidden;
  opacity: 0;
  @include transition;

  &__open {
    visibility: visible;
    opacity: 1;
  }

  .email {
    margin: 4px 0 0 4px;
    color: $txt-primary;
  }
}


.profile {
  display: flex;
  cursor: pointer;

  &__infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 8px;


    &__name {
      font-family: $primary-medium;
      color: $ui-primary;
      font-size: 14px;
    }

    &__settings {
      font-family: $primary-medium;
      font-size: 12px;
      color: $ui-secondary;
    }
  }


}

.divider {
  width: 100%;
  height: 1px;
  margin: 6px 0;
  background-color: $ui-quaternary;
}

.cta {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 2px 4px;
  border-radius: 6px;
  font-family: $primary-medium;
  font-size: 14px;
  color: $ui-primary;
  padding-left: 4px;

  &:hover {
    background-color: $ui-quaternary;
  }

  & > img {
    margin: 0 4px 0 0;
  }
}
