@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/typography';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';

.required_tfa_modal {
  width: 65vw;
  text-align: center;


  @include media(m-device) {
    width: 90vw;
  }

  &__container {
    text-align: left;
    padding: 0 12px;
  }

  img {
    margin-bottom: 10px;
    width: 30px;
  }
  h5, p {
    margin-bottom: 15px;
  }

  a {
    display: inline-block;
  }

  button {
    padding: 10px 20px;
    margin: auto;
  }
}
