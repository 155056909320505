@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/typography';

%shared-title {
  width: 100%;
  position: relative;
  font-family: $primary-SemiBold;
  font-size: $level3;
  color: $txt-primary;
  cursor: pointer;

  &:before {
    position: absolute;
    width: 100%;
    content: '';
    border-top: 1px solid $background-2;
  }
}

.help_title {
  width: 100%;
  margin-bottom: -20px;
  &__content {
    @extend %shared-title;
    &__opened {
      @extend %shared-title;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: $primary-reg;
    font-size: $level3;
    color: $txt-primary;
    padding: 8px 0 8px 0;
  }
}

