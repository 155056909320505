@import '../../../assets/styles/colors';
@import '../../../assets/styles/fonts';
@import '../../../assets/styles/mixins';
@import '../../../assets/styles/typography';


%button-shared {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 12px 0 12px 0;
  border: none;
  @include border-radius(6px);
  font-family: $primary-reg;
  font-size: $level3;
  color: $white;
  cursor: pointer;
  outline: none;
}

.small {
  width: 95px;
}

.medium {
  width: 205px;
}

.large {
  width: 360px;
}

.mobile_full_width {
  width: 100%;
}


.success {
  @extend %button-shared;
  background-color: $confirm;
  &:disabled {
    background-color: $ui-success;
  }
}

.primary {
  @extend %button-shared;
  @include transition;
  background-color: $brand-primary;

  &:hover {
    background-color: darken($color: $brand-primary, $amount: 6%);
  }

  &:focus {
    border-color: $brand-primary-shade;
    box-shadow: 0 0 0 4px $brand-primary-box-shadow;
  }

  &:active {
    border-color: $brand-primary-shade;
    box-shadow: 0 0 0 4px $brand-primary-box-shadow;
  }

  &:disabled {
    background-color: $brand-primary-muted;
  }
}

.primary_muted {
  @extend %button-shared;
  @include transition;
  color: $brand-primary;
  background-color: $brand-primary-muted;


  &:hover {
    background-color: darken($color: $brand-primary-muted, $amount: 10%);
  }

  &:active {
    background-color: darken($color: $brand-primary-muted, $amount: 10%);
  }

  &:disabled {
    background-color: lighten($color: $brand-primary-muted, $amount: 15%);
  }
}

.secondary {
  @extend %button-shared;
  @include transition;
  background-color: $ui-quaternary;
  color: $txt-primary;

  &:hover {
    background-color: $ui-secondary-shade;
  }

  &:focus {
    background-color: $ui-secondary-shade;
    box-shadow: 0 0 0 4px $ui-secondary-box-shadow;
  }

  &:active {
    background-color: $ui-secondary-shade;
    box-shadow: 0 0 0 4px $ui-secondary-box-shadow;
  }

  &:disabled {
    color: darken($color: $ui-quaternary, $amount: 10%);

    &:hover {
      background-color: $ui-quaternary;
    }
  }
}


.secondary_muted {
  @extend %button-shared;
  @include transition;
  background-color: $ui-primary;
  color: $ui-quaternary;

  &:disabled {
    background-color: $ui-secondary;
  }
}

.tertiary {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 12px 24px;
  border: none;
  background-color: $white;
  color: $brand-primary;
  font-family: $primary-medium;
  font-size: $level2;
  outline: none;
  cursor: pointer;
  font-weight: 500;


  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: darken($color: $brand-primary, $amount: 10%);
    text-decoration: underline;
  }

  &:disabled {
    color: lighten($color: $brand-primary, $amount: 40%);
  }
}

.tertiary-muted {
  @extend %button-shared;
  @include transition;
  border-radius: 6px;
  padding: 12px;
  background-color: $white;
  color: $brand-primary;
  border: solid 1px $brand-primary;
  font-family: $primary-SemiBold;

  &:hover {
    background-color: $brand-primary;
    color: $white;
  }
}

.white {
  @extend %button-shared;
  @include transition;
  border-radius: 6px;
  padding: 12px;
  background-color: $white;
  color: $txt-primary;
  border: solid 1px $ui-quaternary;


  &:hover {
    border: solid 1px $ui-tertiary;
  }

  &:active {
    box-shadow: 0 0 0 4px $ui-tertiary-box-shadow;
  }

  &:focus {
    box-shadow: 0 0 0 4px $ui-tertiary-box-shadow;
  }

  &:disabled {
    color: darken($color: $ui-quaternary, $amount: 10%);
  }
}

.gray {
  @extend %button-shared;
  @include transition;
  border-radius: 6px;
  background-color: $ui-tertiary;
  color: $black-gray;
  border: solid 1px $ui-quaternary;

  &:hover {
    border: solid 2px $ui-tertiary;
  }

  &:focus {
    box-shadow: 0 0 0 4px $ui-tertiary-box-shadow;
  }

  &:disabled {
    color: darken($color: $ui-quaternary, $amount: 10%);
  }
}

.inverted-gray {
  @extend %button-shared;
  @include transition;
  border-radius: 8px;
  background-color: $white;
  color: $light-black-gray;
  border: solid 1px $light-black-gray;

  &:hover {
    border: solid 2px $ui-tertiary;
  }

  &:focus {
    box-shadow: 0 0 0 4px $ui-tertiary-box-shadow;
  }

  &:disabled {
    color: darken($color: $ui-quaternary, $amount: 10%);
  }
}

.error {
  @extend %button-shared;
  @include transition;
  background-color: $ui-error-tint-1;
  color: $ui-error;

  &:hover {
    background-color: $ui-error-tint-2;
  }

  &:active {
    background-color: $ui-error-tint-2;
    box-shadow: 0 0 0 4px $ui-error-box-shadow;
  }

  &:focus {
    background-color: $ui-error-tint-2;
    box-shadow: 0 0 0 4px $ui-error-box-shadow;
  }

  &:disabled {
    background-color: lighten($color: $ui-error-tint-1, $amount: 15%);
  }
}


.disabled {
  @extend %button-shared;
  cursor: default;
}

.loading {
  opacity: 0.5;
}


.spinner {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}


.icon {
  vertical-align: middle;
  margin-right: 4px;
}

.reverse_icon {
  vertical-align: middle;
  margin-left: 8px;
}



