@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/typography';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';

.custom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 457px;
  padding: 16px;
  height: fit-content;
  height: -moz-fit-content;
}

.modal {
  display: flex;
  flex-direction: column;

  &__overlay {
    right: 0;
    bottom: 0;
    top: unset;
    left: unset;
    padding: 0;
    border-radius: 12px;
    z-index: 9999999999;
  }

  &__icon {
    width: 38px;
    height: 38px;
    margin: 0 107px 16px 16px;
    border-radius: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/front-static/icons/display/cookie.svg");
  }

  &__title {
    margin-bottom: 4px;
  }

  &__btn__container {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    @include media(s-device) {
      min-width: 100%;
      flex-direction: column;
    }
  }

  &__btn {
    width: 136px;
    height: 38px;
    font-size: 14px;
    padding: 7px 0;
    cursor: pointer;
    @include media(s-device) {
      min-width: 100%;
    }
  }

}

.customContainer {
  top: 0;
  right: 0;
  left: unset !important;
  bottom: unset !important;
}




