@import '../../../assets/styles/colors';
@import '../../../assets/styles/fonts';
@import '../../../assets/styles/mixins';
@import '../../../assets/styles/typography';


.wrapper {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  width: 100%;
}

.label {
  padding-bottom: 4px;

  &__fieldset {
    padding: 0 4px 4px;
    margin-bottom: -40px;
    z-index: 2;
    width: fit-content;
    margin-left: 12px;
    cursor: text;
    margin-top: 15px;
    font-family: $primary-SemiBold;
    @include transition();

    span {
      background-color: $white;
    }

    &__active {
      padding: 0 4px;
      margin-bottom: -12px !important;
      margin-top: -10px;
      border-radius: 6px;
    }
  }
}


.text_area {
  resize: none;
  font-family: $primary-reg;
}


.input {
  height: 48px;
  border: 1px solid $ui-quaternary;
  @include border-radius(4px);
  padding: 12px 16px;
  box-sizing: border-box;
  color: $txt-primary;
  font-size: $level2;
  outline: none;
  width: 100%;
  font-family: $primary-reg;
  @include transition();

  &__new_style {
    font-family: $primary-reg;
    border: 1px solid $ui-tertiary;

    &::placeholder {
      color: transparent !important;
    }

    &__active {
      border: 1px solid $txt-primary;
    }

    &__focused {
      &::placeholder {
        color: $ui-secondary !important;
      }
    }
  }

  &:hover {
    border-color: $ui-tertiary;
  }

  &:focus {
    color: $txt-primary !important;
  }

  &:focus-within {
    opacity: 1;
  }

  &::placeholder {
    font-size: $level2;
    color: $ui-secondary;
  }

  &__iconRight {
    padding: 12px 25px 12px 16px;
  }

  &__iconLeft {
    padding: 12px 16px 12px 35px;
  }


  &__error {
    border-color: $ui-error !important;

    &:focus {
      box-shadow: 0 0 0 4px $ui-error-tint-1;
    }

    &__disabled {
      border: none !important;

      &:hover {
        border: none !important;
      }
    }
  }

  &__disabled {
    &:hover {
      border: 1px solid $ui-quaternary;
    }

    &:focus {
      border: none !important;
    }
  }


  &__custom1 {

    background-color: $ui-quaternary;
    border-color: $ui-quaternary;

    &::placeholder {
      color: $ui-secondary;
    }

    &:hover {
      border-color: $ui-tertiary
    }

    &:focus {
      background-color: white;
      border-color: $ui-tertiary;
    }
  }

  &__seo_input {
    height: 64px;
    font-size: 18px;
    width: 423px;
    @include media(m-device) {
      width: 100%;
    }
  }

  &__required_field {
    border-color: $ui-error !important;

    &:focus {
      box-shadow: 0 0 0 4px $ui-error-tint-1;
    }
  }

  &__gift_card {
    box-shadow: 12px 12px 48px 0 $box-shadow;
  }

  &__authentication-code {
    height: 64px;
    width: 48px;
    font-size: 1.5rem;
    padding: 12px;
    text-align: center;
    caret-color: $brand-primary;
    border-color: $ui-quaternary !important;
    @include media(s-device) {

    }

    &:disabled {
      background-color: $ui-quaternary !important;
    }

    &__valid {
      border-color: $ui-quaternary !important;

    }

    &__error {
      border-color: $ui-quaternary !important;
    }

  }

  &__wrapper {
    position: relative;
  }

  &__isAmount {
    background-image: url("/front-static/icons/display/euro-symbol.svg");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: inherit;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }


  &__icon {
    width: 24px;
    position: absolute;
    top: 12px;
    bottom: 12px;

    &__left {
      left: 5px;
    }

    &__right {
      right: 5px;
    }

  }

  &__ui_primary_border {
    border: 1px solid $ui-primary;

    &:hover {
      border: 1px solid $ui-primary !important;
    }
  }

}

.wrapper input:-webkit-autofill,
.wrapper input:-webkit-autofill:hover,
.wrapper input:-webkit-autofill:focus,
.wrapper input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
