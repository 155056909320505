@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/mixins';
@import '../../../../../assets/styles/typography';
@import '../../../../../assets/styles/fonts';

.participate {
  width: 100%;

  &__cta {
    width: 100%;
    height: 56px;

    &__frozen {
      text-decoration: unset !important;
      cursor: unset !important;
    }

    &__link {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &>h5 {
    text-decoration: underline;
    cursor: pointer;
  }
}
