@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';
@import '../../../../assets/styles/fonts';

.sub {
  z-index: 669;
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  width: 100vw;
  padding: 10px 5vw;
  background-color: $ui-primary;
  gap: 8px;

  & > a {
    cursor: pointer;
    width: fit-content;
  }

  @include media(m-device) {
    top: 56px;
    flex-direction: row;
    justify-content: space-between;
    height: fit-content;
    max-height: 80px;
  }

  &__amount {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 140px;

    &__txt {
      margin-right: 4px;
    }
  }
}
