@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/typography';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  @include media(s-inverse-device) {
    width: 507px;
  }

  &__name {
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
}

.desc {
  padding-top: 8px;
  max-width: 507px;
}

.first_component {
  padding-top: 16px;
}
