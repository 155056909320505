@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';
@import '../../../../assets/styles/fonts';

.mangopay_cgu_modal_overlay {
  max-width: 480px;
  min-width: unset;
  @include media(s-device) {
    max-height: 75dvh;
  }
}

.mangopay_cgu_modal {
  max-width: 432px;
  padding: 8px;

  h5 {
    color: $txt-primary;
    margin-bottom: 24px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    width: 95%;
  }

  &__caption {
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    background-color: #F00004;
    width: fit-content;
    border-radius: 8px;
    height: 52px;

    & > p {
      font-size: 17px;
      font-family: $primary-SemiBold;
      font-weight: 600;
    }
  }

  &__text {
    line-height: 24px;
    margin-bottom: 24px;
    font-family: $primary-reg;
    font-weight: 500;

    & > strong {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      font-family: $primary-SemiBold;
    }

    & > b {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      font-family: $primary-SemiBold;
    }

    & > a {
      color: inherit;
        text-decoration: underline;
    }

    & > a:focus {
      outline: none;
      border: unset;
    }
  }

  &__buttons {
    margin-top: 8px;
    display: flex;
    gap: 16px;
    flex-direction: column;
  }

  &__confirm_button {
    font-size: 18px;
    font-style: normal;
    font-family: $primary-SemiBold;
  }

  &__reject_button {
    color: $black-gray;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    font-family: $primary-medium;
  }
}
