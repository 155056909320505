@import '../../../assets/styles/mixins';


.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80vw;
  max-width: 1140px;
  margin: 76px auto 32px auto;

  @include media(m-device) {
    width: 90vw;
    margin: 48px auto 20px auto;
  }
}

.select {
  width: 141px;

  &__custom__wrapper {
    width: 141px;
    @include media(m-device) {
      margin: 32px 0 48px 0;
    }
  }

  &__control {
    height: 38px;
  }

  &__menu {
    > div {
      height: 38px;
    }
  }
}



