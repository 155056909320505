
.spinner {
  display: flex;
  margin: 10px auto 10px auto;
  height: 30px;
  width: 30px;
}

/* src/WaveSpinner.css */
.wave_spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  margin: auto;
}

.dot {
  margin: 0;
  animation: wave 1.4s infinite ease-in-out;
}

.dot.blue {
  width: 14%;
  height: auto;
  animation-delay: 0;
}

.dot.yellow {
  width: 12%;
  height: auto;
  animation-delay: 0.2s;
}

.dot.pink {
  width: 16%;
  height: auto;
  animation-delay: 0.4s;
}


@keyframes wave {
  0%, 60%, 100% {
    transform: translateY(1px);
  }
  30% {
    transform: translateY(-12px);
  }
}
