@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';


.navbar {
  padding-left: 8px;

  &__right {
    display: flex;
    align-items: center;

    &__btn {
      height: 38px;
      padding: 16px !important;
      margin-left: 8px;
      font-size: $level2;
    }
  }

}

.avatar {
  cursor: pointer;
  margin-left: 8px;
}







