@mixin media($size) {
  @if $size == xs-device {
    @media (max-width: 340px) {
      @content;
    }
  } @else if $size == s-device {
    @media (max-width: 600px) {
      @content;
    }
  } @else if $size == s-inverse-device {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == m-device {
    @media (max-width: 1132px) {
      @content;
    }
  } @else if $size == l-device {
    @media (min-width: 1133px) {
      @content;
    }
  } @else if $size == xl-device {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == ie {
    @media only screen and (min-width: 0 \0
    ) {
      @content
    }
  }
}

@mixin hide-on-m-device {
  @include media(m-device) {
    display: none;
  }
}

@mixin hide-on-s-device {
  @include media(s-device) {
    display: none;
  }
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin transition {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
}

@mixin transform($value1, $value2) {
  transform: translate($value1, $value2);
  -webkit-transform: translate($value1, $value2);
  -moz-transform: translate($value1, $value2);
  -ms-transform: translate($value1, $value2);
}


@mixin render-background-color {
  $colors: "brand-primary" #E22B76,
  "brand-secondary" #00A5E2,
  "brand-accent" #FAC700,
  "brand-primary-muted" #FCE9F1,
  "brand-secondary-muted" #E0F5FF,
  "ui-primary" #111E3D,
  "ui-secondary" #A3AFC2,
  "ui-tertiary" #E1E5EB,
  "ui-quaternary" #EBEFF5,
  "ui-success" #2BE283;


  @each $i in $colors {
    .#{nth($i, 1)} {
      background: nth($i, 2);
    }
  }
}

@mixin render-color {
  $colors: "brand-primary" #E22B76,
  "brand-secondary" #00A5E2,
  "brand-accent" #FAC700,
  "brand-primary-muted" #FCE9F1,
  "brand-secondary-muted" #E0F5FF,
  "ui-primary" #111E3D,
  "ui-secondary" #A3AFC2,
  "ui-tertiary" #E1E5EB,
  "ui-quaternary" #EBEFF5,
  "ui-success" #2BE283,
  "ui-error" #fb2838,
  "txt-primary" #232e69,
  "txt-secondary" #a3afc2,
  "txt-alert" #f58b40,
  "orange" #ff6900,
  "white" #ffffff,
  "ui-dark-grey" #424242,
  "ui-warning" #EB853D,
  "gray" #222222;


  @each $i in $colors {
    .#{nth($i, 1)} {
      color: nth($i, 2);
    }
  }
}




