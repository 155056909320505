@import '../../../assets/styles/fonts';
@import '../../../assets/styles/colors';

.cg__switch {
  display: flex;
  align-items: center;
  margin: 0;

  &__toggle {
    display: inline-block;
    background: $ui-quaternary;
    padding: 2px;
    border-radius: 100px;
    width: 42px;
    height: 24px;
    cursor: pointer;
    outline: 0;
    margin: 0 10px 0 0;
    box-sizing: border-box;
    transition: 0.15s linear background;

    &::before {
      content: '';
      display: inline-block;
      border-radius: 50%;
      background: white;
      width: 20px;
      height: 20px;
      outline: 0;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      box-shadow: 0 2px 4px rgba(0, 0.5, 1, 0.5);
      transform: translateX(0);
      transition: 0.15s linear transform;
    }

    &__on {
      background: $brand-primary;
    }

    &__on::before {
      transform: translateX(18px);
    }
  }

  &__label {
    font-size: 14px;
    line-height: 1.2;
    font-family: $primary-reg;
    font-weight: 300;
    letter-spacing: 0.5px;
    margin: 0 0 0 10px;
  }
}
