@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';

.select {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 184px;
  height: 38px;
  margin-right: 10px;
  cursor: pointer;

  &__arrow {

    &_closed {
      @include transition();
    }

    &_open {
      @include transition();
      transform: rotateZ(180deg);
    }

  }
}

.container {
  position: absolute;
  top: 85px;
  right: 220px;
  width: 554px;
  height: fit-content;
  padding: 28px 16px;
  border: 1px solid $ui-quaternary;
  border-radius: 12px;
  box-shadow: 0 16px 48px 0 $box-shadow;
  background-color: $white;
  visibility: hidden;
  opacity: 0;

  &_open {
    @include transition();
    visibility: visible;
    opacity: 1;
  }
}

.card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;
  border-radius: 8px;
  border: solid 1px $ui-quaternary;

  &__label {
    position: absolute;
    top: -16px;
    right: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    padding: 8px;
    border-radius: 4px;
    background-color: $brand-primary;
    font-weight: 500;
  }

  &__free {
    height: 100px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;

    &__row {
      display: flex;
      align-items: center;
      width: 100%;
      height: 86px;
      padding: 8px;
      border-radius: 12px;
      cursor: pointer;
      border: 1px solid $ui-transparent;
      @include transition();

      &:hover {
        border: 1px solid $ui-quaternary;
      }
    }
  }

  &__comm {
    justify-content: flex-start;
    align-items: center;
    height: 86px;
    cursor: pointer;

    &__content {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 8px;
      border-radius: 12px;
      border: 1px solid $ui-transparent;
      @include transition();


      &:hover {
        border: 1px solid $ui-quaternary;
      }
    }


  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  margin: 0 12px 0 0;

  & > img {
    width: 100%;
    height: 100%;
  }
}










