@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';


.navbar {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19px 24px 19px 24px;
  background-color: $white;
  overflow: hidden;

  &:after {
    position: absolute;
    content: '';
    border-bottom: 1px solid $ui-quaternary;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &__right {
    display: flex;
    align-items: center;

    &__download {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 38px;
      margin: 0 16px;
      border-radius: 8px;
      background-color: $white;
      cursor: pointer;
      border: none;
    }

    &__cta {
      width: fit-content;
      height: 38px;
      margin-right: 8px;
      padding: 16px;
      font-size: $level2;
    }

    &__menu {
      cursor: pointer;
      width: 24px;
      height: 24px;

      &__open {
        width: 24px;
        height: 24px;
        background-image: url("/front-static/icons/navigation/close.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @include transition;
        cursor: pointer;

      }

      &__close {
        width: 24px;
        height: 24px;
        background-image: url("/front-static/icons/navigation/menu.svg");
        transform: rotate(-180deg);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @include transition;
        cursor: pointer;

      }
    }
  }
}

.sidebar {
  z-index: 9999999999;
  position: fixed;
  top: 56px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  transform: translateX(-100%);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
  background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
  opacity 0.55s ease;


  &__open {
    transform: translateX(0);
  }

  &__close {
    transform: translateX(-100%);
  }


  &__btn {
    margin-top: 34px;

    &__cta {
      margin-bottom: 8px;
      width: 90vw;
      height: 48px;
      max-width: 295px;
    }
  }

}


.collapsible {
  margin: 0 0 -40px 0;


  &__trigger {
    display: flex;
    align-items: center;
    height: 56px;
    padding: 0 40px 0 40px;

    &:before {
      bottom: 0;
      left: 0;
    }

    &:after {
      left: unset;
      right: 40px;
    }


    &__opened {
      display: flex;
      align-items: center;
      background-color: $ui-quaternary;
      height: 56px;
      padding: 0 40px 0 40px;


      &:before {
        bottom: 50px !important;
      }

      &:after {
        left: unset;
        right: 40px;
      }

    }
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px 0 40px;
    color: $txt-primary;
    font-family: $primary-medium;
    font-size: $level3;
  }

  &__inner {
    margin-top: -16px;
  }

  &__outer {

  }


}
















