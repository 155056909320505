@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';


.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 218px;

  &__col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &__title {
      margin-bottom: 8px;
    }

    &__icon {
      margin-left: 5px;
      width: 16px;
    }

    > a {
      margin-bottom: 6px;

      > div {
        &:hover {
          color: $txt-primary;
        }
      }

    }
  }

}

