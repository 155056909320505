@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/typography';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';
@import "../../../../assets/styles/_colors.scss";

.custom_relays {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: fit-content;
  height: -moz-fit-content;

  .modal {
    display: flex;
    flex-direction: column;


    &__icon {
      width: 76px;
      height: 76px;
      margin: 56px auto 32px auto;
      border-radius: 12px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    & > h4 {
      text-align: center;
      font-size: 20px;
      margin: 15px 0 25px 0;

    }

    &__text {
      text-align: center;
      margin: 0 0 4px 0;
    }

    &__lastText {
      text-align: center;
      margin: 0 0 25px 0;
    }

    &__btn__container {
      display: flex;
      flex-direction: row;
    }

    .customCancelButton {
      margin-left: 10px;
      border-radius: 9px;
      background-color: $ui-error-tint-2;
      width: 100%;
      color: $ui-error;
    }

    .customConfirmButton {
      border-radius: 9px;
      width: 100%;
      color: $txt-primary;
    }

  }
}





