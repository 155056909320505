@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';


.hint {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  img {
    height: 36px;
  }

  @include media(m-device) {
    flex-direction: column;
    justify-content: center;
  }

  > div {
    max-width: 840px;
    @include media(m-device) {
      margin: 24px 0 24px 0;
    }

    > div > a {
      color: $ui-secondary !important;
    }
  }

}
