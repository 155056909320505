@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/typography';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';

.custom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 555px;
  padding: 24px;
  height: fit-content;
  height: -moz-fit-content;
}

.customRootClass {
  z-index: 99999999999;
}

.customOverlay {
  z-index: 8;
}

.custom_container {
  position: relative;
  z-index: 99999;
}

.modal {
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: 4px;
  }

  &__btn__container {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    @include media(s-device) {
      min-width: 100%;
      flex-direction: column;
    }
  }

  &__btn {
    width: 136px;
    height: 38px;
    font-size: 14px;

    @include media(s-device) {
      min-width: 100%;
    }

    &__confirm {
      width: 179px;
    }

    &__mobile_display {
      min-width: 100%;
      @include media(s-inverse-device) {
        display: none;
      }
    }

    &__mobile_hide {
      @include media(s-device) {
        display: none;
      }
    }
  }

}


.divider {
  max-width: 555px;
  height: 1px;
  margin: 16px 0;
  background-color: $ui-quaternary;
}
