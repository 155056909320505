@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/typography';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';

.custom {
  width: 458px;
  min-height: 305px;
  padding: 0;
  @include media(m-device) {
    width: 90vw;
    padding: 0;
  }

}
.form__second__select {
  width: 100%;
  z-index: 6 !important;
}

.header {
  padding: 24px;

  p {
    max-width: 90%;
    margin-top: 8px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-top: 1px solid $ui-quaternary;

  &__content {
    padding: 24px;
  }

  &__input {
    margin-bottom: 24px;
  }

  &__checkbox {
    padding: 12px 0 12px 0;

  }

  &__fees_container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__submit {
    &__cta {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  &__terms {
    margin-top: 16px;
  }

}

.divider {
  position: relative;
  margin: 24px auto;
  font-family: $primary-medium;
  text-align: center;
  color: $txt-primary;
  font-weight: 900;
  font-size: 15px;
  vertical-align: top;

  &:before {
    content: "";
    position: absolute;
    height: 1px;
    background-color: $ui-quaternary;
    width: 45%;
    top: 9px;
    left: 0;
  }

  &:after {
    content: "";
    position: absolute;
    width: 45%;
    top: 9px;
    height: 1px;
    right: 0;

    background-color: $ui-quaternary;
  }
}

.button__container {
  margin-top: 24px;
}

